import { autoinject, bindable, BindingEngine, containerless } from 'aurelia-framework';
import { Disposable } from 'aurelia-binding';
import { AuthenticationProvider } from 'providers/authentication-provider';
import { Result, SicAlert, ThreatsApiClient, WriteComment } from 'services/cyber-api';
import { EventKeys } from '../../../../../enums/event-keys';
import { EventAggregator } from 'aurelia-event-aggregator';
import { UxBlade } from 'components/layout/ux-blade/ux-blade';

@autoinject()
@containerless()
export class ThreatActivities {
    @bindable() private threat: SicAlert;
    @bindable() private onCommentAdded: (model: { threat: SicAlert, comment: string }) => void;
    @bindable() private company: string;
    @bindable() private activityLogs: Result[] | undefined = undefined;
    @bindable() private onHide: () => void = () => {};
    private readonly activitiesDisplayAmount: number = 3;
    private latestActivities: Result[];
    public newComment: string;
    private activitiesBlade: UxBlade;
    private activityLogsCollectionChangedSubscription: Disposable;
    private keypressCallback: EventListenerOrEventListenerObject;

    constructor(
        private authenticationProvider: AuthenticationProvider,
        private threatsApi: ThreatsApiClient,
        private bindingEngine: BindingEngine,
        private eventAggregator: EventAggregator
    ) {
        this.keypressCallback = this.keypress.bind(this);
    }

    private attached(): void {
        if (this.activityLogs)
            this.activityLogsCollectionChangedSubscription = this.bindingEngine.collectionObserver(this.activityLogs)
                .subscribe(this.activityLogsCollectionChanged.bind(this));
        this.initialise();

        // Keypress Events for [Esc]
        window.addEventListener('keydown', this.keypressCallback, false);
    }

    private detached(): void {
        if (this.activityLogsCollectionChangedSubscription)
            this.activityLogsCollectionChangedSubscription.dispose();

        // Keypress Events for [Esc]
        window.removeEventListener('keydown', this.keypressCallback);
    }

    private async keypress(e: KeyboardEvent): Promise<void> {
        if (e.code === 'Escape') {
            await this.activitiesBlade.hide();
            this.onHide();
        }
    }

    private initialise(): void {
        this.initialiseLatestComments(this.activityLogs);
    }

    private threatChanged(): void {
        this.initialise();
    }

    // this method will be called when the activityLogs property changes entirely.
    private activityLogsChanged() {
        if (!this.activityLogsCollectionChangedSubscription)
            this.activityLogsCollectionChangedSubscription = this.bindingEngine.collectionObserver(this.activityLogs)
                .subscribe(this.activityLogsCollectionChanged.bind(this));
        this.initialiseLatestComments(this.activityLogs);
    }

    // this method will be called when the activityLogs property changes partially.
    private activityLogsCollectionChanged(splices: string[]): void {
        this.initialiseLatestComments(this.activityLogs);
    }

    private initialiseLatestComments(activities: Result[], amount: number = this.activitiesDisplayAmount): void {
        this.latestActivities = activities?.slice(0, amount);
    }

    private async submitComment(comment: string): Promise<void> {
        if (!comment) return;

        // Construct a comment body, and submit it to the API which will add it to the threat's comments
        const writtenComment = new WriteComment();
        writtenComment.comment = comment;
        // Add a comment to the threat in memory and update the list to make it look like the comment was added instantly
        this.activityLogs.unshift(new Result({
            userName: this.authenticationProvider.profile.name,
            content: comment,
            date: new Date(),
            action: 'Threats_AddComment'
        }));
        this.initialiseLatestComments(this.activityLogs);
        this.newComment = undefined;

        try {
            await this.threatsApi.addComment(this.threat.uuid, this.company, writtenComment);

            this.eventAggregator.publish(EventKeys.onThreatCommentAdded, { threat: this.threat, comment });
        } catch (error) {
            throw error;
        }
    }
}
