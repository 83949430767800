import { bindable, containerless } from 'aurelia-framework';
import moment from 'moment';
import { ISicAlertOccurrence } from 'services/cyber-api';
import { UxBlade } from '../../../../../components/layout/ux-blade/ux-blade';

@containerless()
export class ThreatOccurrences {
    @bindable() private occurrences: ISicAlertOccurrence[];
    private filteredOccurrences: ISicAlertOccurrence[];
    private blade: UxBlade;

    private occurrencesChanged(): void {
        this.filterOccurrences();
    }

    private filterOccurrences(day: moment.Moment = undefined, hour: moment.Moment = undefined): void {
        let filteredOccurrences: ISicAlertOccurrence[];

        if (moment.isMoment(day))
            // Filter on the selected day
            filteredOccurrences = this.occurrences.filter((x) =>
                day.isSame(moment(x.createdAt.valueOf()).startOf('day'))
            );
        else if (moment.isMoment(hour))
            // Filter on the selected hour
            filteredOccurrences = this.occurrences.filter((x) =>
                hour.isSame(moment(x.createdAt.valueOf()).startOf('hour'))
            );
        else
            // Clear filters
            filteredOccurrences = this.occurrences;

        this.filteredOccurrences = filteredOccurrences;
    }

    public openBlade(): void {
        this.blade.show();
    }
}
