import { autoinject, bindable, observable } from 'aurelia-framework';
import {
    Detection,
    DetectionActions,
    DetectionsApiClient,
    DetectionStatus,
    DetectionTypes,
    SicAlert
} from './../../../../../services/cyber-api';
import { StateApi } from 'services/state-api';
import { Toast } from 'utilities/toast';
import { UxBlade } from 'components/layout/ux-blade/ux-blade';
import { Utilities } from 'utilities/utilities';
import { BusinessMap, CounterMeasuresApiClient } from 'services/cyber-api';

@autoinject()
export class ThreatRespondListItem {
    @bindable() public detection: Detection;
    @bindable() public businessMap: BusinessMap;
    @bindable() public fleetVessels: BusinessMap[];
    @bindable() public threat: SicAlert;
    @bindable() public xchangeCompatibleHardware: boolean;
    @bindable() public requiredXchangeFirmwareVersion: string;

    public allowActions: boolean;
    private DetectionTypes: typeof DetectionTypes = DetectionTypes;
    private DetectionStatus: typeof DetectionStatus = DetectionStatus;
    private DetectionActions: typeof DetectionActions = DetectionActions;
    private blade: UxBlade;
    private agreedWithTerms: boolean = false;
    private actionExecuting: boolean = false;
    private comment: string;

    constructor(
        private detectionsApi: DetectionsApiClient,
        private countermeasureApi: CounterMeasuresApiClient,
        private state: StateApi) {
    }

    private async attached(): Promise<void> {
        // Only allow actions when the detection has a status of Open (to block), Failed (to retry) or Succeeded (to unblock or block)
        this.allowActions =
            this.detection.status === DetectionStatus.Open
            || this.detection.status === DetectionStatus.Failed
            || this.detection.status === DetectionStatus.Succeeded;
    }

    private onFleetwideCountermeasuresBladeHide = async (detection: Detection): Promise<void> => {
        // Hide blade if it still exists. Note: it can already be removed by navigating away from the current threat.
        if (this.blade) this.blade.hide();

        // If a Detection is present, Update the current detection.
        if (detection) this.detection = detection;
    };

    public async execute(): Promise<void> {
        // Don't execute when user hasn't agreed with the terms or no actions are allowed
        if (!this.agreedWithTerms || !this.allowActions || this.actionExecuting) return;

        this.actionExecuting = true;

        try {
            const updatedDetection = await this.detectionsApi.execute(this.detection.id, this.state.company(), this.comment);

            // Hide blade if it still exists. Note: it can already be removed by navigating away from the current threat.
            if (this.blade) this.blade.hide();

            // on success, update the current item
            this.detection = updatedDetection;
            try {
                // Find the related actionStep
                const actionStep = this.threat.countermeasures.filter(x => x.action_steps.find(x => x.object.target.domain_name == this.detection.target || (x.object.target.mac_addr == '@mac_addr' && this.detection.type === 'MacAddress')))[0];
                await this.countermeasureApi.activate(actionStep.uuid, this.state.company(), this.detection.type === 'MacAddress' ? "MAC Address blocked through XChange countermeasure." : "Domain blocked through XChange countermeasure.");
            } catch (error) {
                // Dont catch this, because its possible that a action is already executed for specified document.
                console.error(error);
            }

            Toast.success(`${updatedDetection.lastAction} ${updatedDetection.target} is queued.`);
        } catch (error) {
            Toast.error(`Oops, ${error}`);
        } finally {
            this.actionExecuting = false;
        }
    }

    public copyTarget(target: string): void {
        try {
            Utilities.copyToClipboard(target);
        } catch (error) {
            Toast.warning('Unable to copy target to clipboard');
            return;
        }

        Toast.info('Copied to clipboard');
    }
}
