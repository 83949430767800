import { bindable } from 'aurelia-templating';
import { UxBlade } from 'components/layout/ux-blade/ux-blade';
import { ISicAlertOccurrence } from 'services/cyber-api';
import { ICollapsible } from 'models/icollapsible';

export class ThreatOccurrencesBlade {
    @bindable() private occurrences: ICollapsibleOccurrence[];
    private blade: UxBlade;

    public async show(): Promise<void> {
        this.occurrences = this.mapToCollapsibleOccurrences(this.occurrences)
            // Sort the occurrences by date, descending
            .sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);

        if (this.blade)
            await this.blade.show();
    }

    private collapseAllOccurrences(collapsed: true): void {
        // To trigger change listeners on the array, we need to set it with a new 'clone' array
        const expanded = [...this.occurrences];
        expanded.forEach((x) => x.collapsed = collapsed);
        this.occurrences = expanded;
    }

    private mapToCollapsibleOccurrences(occurrences: ISicAlertOccurrence[]): ICollapsibleOccurrence[] {
        return occurrences.map((occurrence) => {
            return {
                ...occurrence,
                collapsed: true
            };
        });
    }
}

interface ICollapsibleOccurrence extends ICollapsible, ISicAlertOccurrence {
}
