import { bindable, containerless } from 'aurelia-framework';

@containerless
export class ThreatKpi {
    @bindable() private icon: string;
    @bindable() private label: string;
    @bindable() private subLabel: string;
    @bindable() private tooltip: string;
    @bindable() private onIconClick: () => {};
    private iconClickable: boolean = false;

    private async attached(): Promise<void> {
        if (this.onIconClick) this.iconClickable = true;        
    }

    private clickHandler(): void {
        if (this.onIconClick) this.onIconClick();
    }
}
